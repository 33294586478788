const setup = () => {

  const getTheme = () => {
    if (window.localStorage.getItem('dark')) {
      return JSON.parse(window.localStorage.getItem('dark'))
    }

    return !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  }

  const setTheme = (value) => {
    window.localStorage.setItem('dark', value)
  }

  return {
    loading: true,
    isDark: getTheme(),
    toggleTheme() {
      this.isDark = !this.isDark
      setTheme(this.isDark)
    },
    setLightTheme() {
      this.isDark = false
      setTheme(this.isDark)
    },
    setDarkTheme() {
      this.isDark = true
      setTheme(this.isDark)
    },
    toggleSidbarMenu() {
      this.isSidebarOpen = !this.isSidebarOpen
    },
    isSettingsPanelOpen: false,
    openSettingsPanel() {
      this.isSettingsPanelOpen = true
      this.$nextTick(() => {
        this.$refs.settingsPanel.focus()
      })
    },
    isNotificationsPanelOpen: false,
    openNotificationsPanel() {
      this.isNotificationsPanelOpen = true
      this.$nextTick(() => {
        this.$refs.notificationsPanel.focus()
      })
    },
    isSearchPanelOpen: false,
    openSearchPanel() {
      this.isSearchPanelOpen = true
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
    },
    isMobileSubMenuOpen: false,
    openMobileSubMenu() {
      this.isMobileSubMenuOpen = true
      this.$nextTick(() => {
        this.$refs.mobileSubMenu.focus()
      })
    },
    isMobileMainMenuOpen: false,
    openMobileMainMenu() {
      this.isMobileMainMenuOpen = true
      this.$nextTick(() => {
        this.$refs.mobileMainMenu.focus()
      })
    }
  }
}
window.setup = setup
