function CronUI(e, t) {
    if (e instanceof HTMLElement) this.el = e; else {
        if ("string" != typeof e) throw "CronUI: container parameter in initialization must be an html element or a string selector.";
        this.el = document.querySelector(e)
    }
    this.options = t ? t : {}, this.render(), "string" != typeof this.options.initial && (this.options.initial = "* * * * *"), this.setCronString(this.options.initial), this.currentValue = this.options.initial
}

CronUI.prototype.render = function () {
    for (var e = this.el, t = "", r = 0; 60 > r; r++) {
        var n = 10 > r ? "0" : "";
        t += "<option value='" + r + "'>" + n + r + "</option>\n"
    }
    for (var o = "", r = 0; 24 > r; r++) {
        var n = 10 > r ? "0" : "";
        o += "<option value='" + r + "'>" + n + r + "</option>\n"
    }
    for (var i = "", r = 1; 32 > r; r++) {
        if (1 == r || 21 == r || 31 == r) var s = "st"; else if (2 == r || 22 == r) var s = "nd"; else if (3 == r || 23 == r) var s = "rd"; else var s = "th";
        i += "<option value='" + r + "'>" + r + s + "</option>\n"
    }
    for (var c = "", a = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"], r = 0; r < a.length; r++) c += "<option value='" + (r + 1) + "'>" + a[r] + "</option>\n";
    for (var l = "", u = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], r = 0; r < u.length; r++) l += "<option value='" + r + "'>" + u[r] + "</option>\n";
    for (var d = "", h = [ "hour", "day", "week", "month", "year"], r = 0; r < h.length; r++) d += "<option value='" + h[r] + "'>" + h[r] + "</option>\n";
    var m = {};
    e.insertAdjacentHTML("beforeend", "<span class='cron-period'><select>" + d + "</select></span>");
    var p = e.querySelector(".cron-period select");
    p.classList =  'inline bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ' +  p.classList;

    p.addEventListener("change", this.periodChanged.bind(this)), p.addEventListener("change", this.changeEvent.bind(this)), e.insertAdjacentHTML("beforeend", "<span class='cron-block cron-block-dom'> on the <select name='cron-dom'>" + i + "</select> </span>"), m.dom = e.querySelector(".cron-block-dom"), e.insertAdjacentHTML("beforeend", "<span class='cron-block cron-block-month'> of <select name='cron-month'>" + c + "</select> </span>"), m.month = e.querySelector(".cron-block-month"), e.insertAdjacentHTML("beforeend", "<span class='cron-block cron-block-mins'> at <select name='cron-mins'>" + t + "</select> minutes past the hour </span>"), m.mins = e.querySelector(".cron-block-mins"), e.insertAdjacentHTML("beforeend", "<span class='cron-block cron-block-dow'> on <select name='cron-dow'>" + l + "</select> </span>"), m.dow = e.querySelector(".cron-block-dow"), e.insertAdjacentHTML("beforeend", "<span class='cron-block cron-block-time'> at <select name='cron-time-hour' class='cron-time-hour'>" + o + "</select>:<select name='cron-time-min' class='cron-time-min'>" + t + " </span>"), m.time = e.querySelector(".cron-block-time");
    for (var y in m) [].forEach.call(m[y].querySelectorAll("select"), function (e) {
        e.classList =  'inline bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ' +  e.classList;
        e.addEventListener("change", this.changeEvent.bind(this))
    }.bind(this));
    this.blocks = m

}, CronUI.prototype.periodChanged = function () {
    var e = this.blocks, t = this.el.querySelector(".cron-period select"), r = t.options[t.selectedIndex].value;
    for (var n in e) e[n].style.display = "none";
    if (CronUI.displayMatrix.hasOwnProperty(r)) for (var o = CronUI.displayMatrix[r], i = 0; i < o.length; i++) e[o[i]].style.display = ""
}, CronUI.prototype.changeEvent = function () {
    this.currentValue = this.getCronString(), "function" == typeof this.options.changeEvent && this.options.changeEvent(this.currentValue)
}, CronUI.prototype.getCronString = function () {
    var e, t, r, n, o;
    e = t = r = n = o = "*";
    var i = this.blocks, s = function (e) {
        return e.options[e.selectedIndex].value
    }, c = s(this.el.querySelector(".cron-period select"));
    switch (c) {
        case"hour":
            e = s(i.mins.querySelector("select"));
            break;
        case"day":
            e = s(i.time.querySelector(".cron-time-min")), t = s(i.time.querySelector(".cron-time-hour"));
            break;
        case"week":
            e = s(i.time.querySelector(".cron-time-min")), t = s(i.time.querySelector(".cron-time-hour")), o = s(i.dow.querySelector("select"));
            break;
        case"month":
            e = s(i.time.querySelector(".cron-time-min")), t = s(i.time.querySelector(".cron-time-hour")), r = s(i.dom.querySelector("select"));
            break;
        case"year":
            e = s(i.time.querySelector(".cron-time-min")), t = s(i.time.querySelector(".cron-time-hour")), r = s(i.dom.querySelector("select")), n = s(i.month.querySelector("select"));
            break;
        default:
            return c
    }
    return [e, t, r, n, o].join(" ")
}, CronUI.prototype.setCronString = function (e) {
    var t = this.blocks, r = CronUI.getCronType(e);
    if (!r) return !1;
    for (var n = e.split(" "), o = {
        mins: n[0],
        hour: n[1],
        dom: n[2],
        month: n[3],
        dow: n[4]
    }, i = CronUI.displayMatrix[r], s = 0; s < i.length; s++) {
        var c = i[s];
        "time" == c ? (t[c].querySelector(".cron-time-hour").value = o.hour, t[c].querySelector(".cron-time-min").value = o.mins) : t[c].querySelector("select").value = o[c]
    }
    return this.el.querySelector(".cron-period select").value = r, this.periodChanged(), this
}, CronUI.displayMatrix = {
    hour: ["mins"],
    day: ["time"],
    week: ["dow", "time"],
    month: ["dom", "time"],
    year: ["dom", "month", "time"]
}, CronUI.cronTypes = {
    hour: /^\d{1,2}\s(\*\s){3}\*$/,
    day: /^(\d{1,2}\s){2}(\*\s){2}\*$/,
    week: /^(\d{1,2}\s){2}(\*\s){2}\d{1,2}$/,
    month: /^(\d{1,2}\s){3}\*\s\*$/,
    year: /^(\d{1,2}\s){4}\*$/
}, CronUI.getCronType = function (e) {
    e = e ? e : this.getCronString();
    var t = /^((\d{1,2}|\*)\s){4}(\d{1,2}|\*)$/;
    if ("string" == typeof e && t.test(e)) {
        for (var r = e.split(" "), n = [0, 0, 1, 1, 0], o = [59, 23, 31, 12, 6], i = 0; i < r.length; i++) if ("*" != r[i]) {
            var s = parseInt(r[i]);
            if (!(s <= o[i] && s >= n[i])) return
        }
        for (var c in CronUI.cronTypes) if (CronUI.cronTypes[c].test(e)) return c
    }
};
module.exports = CronUI
