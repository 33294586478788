import './app.scss'

import Alpine from 'alpinejs'
import timeago from './timeago.js'
window.Alpine = Alpine
window.timeago = timeago

// Tooltips!!!!
require('flowbite/dist/flowbite.min')
require('cmd-dialog')
require('./template_setup.js')

import CronUI from './js/cron-ui.min'
window.CronUI = CronUI
import { isValidCron } from 'cron-validator'
window.isValidCron = isValidCron

import CalHeatmap from 'cal-heatmap';
window.CalHeatmap = CalHeatmap
// Optionally import the CSS
import 'cal-heatmap/cal-heatmap.css';

import 'htmx.org';
document.addEventListener('DOMContentLoaded', function () {

  window.Alpine.start();

  [...document.querySelectorAll('.timeago')].forEach(function (el) {
    el.innerHTML = timeago(new Date(el.title));
  })

  const el = document.getElementById('main-search-cancel');
  if (el) {
    el.addEventListener('click', function (event) {
      window.location = window.location.pathname + window.location.hash;
    });
  }
  document.addEventListener('keyup', function (e) {
    if (e.key === '/') {
      const el = document.getElementById('main-search-input');
      if (el) {
        e.preventDefault();
        el.focus();
      }
    }
  });
});
